// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/KarieraWelcome.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/bgKarieraMob.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KarieraWelcome_welcomeSection__U6kGD {
	/* text-align: center; */
	height: 500px;
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@media screen and (max-width: 768px) {
		height: 160px;
		background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
	}
}

.KarieraWelcome_text__MsTT7 {
	font-size: 36px;
	font-weight: 600;
	line-height: 1.2;
	margin: 0;
	padding-top: 200px;
	padding-left: 140px;
	color: #fff;
	text-shadow: 0 4px 4px #00000099;

	@media screen and (max-width: 768px) {
		font-size: 16px;
		padding-top: 70px;
		padding-left: 20px;
	}
}

.KarieraWelcome_textPhone__EzMDB {
	display: inline-block;
	font-size: 32px;
	font-weight: 600;
	line-height: 1.2;
	margin: 0;
	margin-left: 140px;
	margin-top: 15px;
	color: #fff;
	text-shadow: 0 4px 4px #00000099;
	text-decoration: none;
	&:hover,
	&:focus {
		/* text-decoration: underline; */
		color: #ffa502;
	}
	@media screen and (max-width: 768px) {
		font-size: 16px;
		margin-left: 20px;
		margin-top: 5px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/KarieraWelcome/KarieraWelcome.module.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB,aAAa;CACb,yDAAwD;CACxD,sBAAsB;CACtB,2BAA2B;CAC3B,4BAA4B;CAC5B;EACC,aAAa;EACb,yDAAsD;CACvD;AACD;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,SAAS;CACT,kBAAkB;CAClB,mBAAmB;CACnB,WAAW;CACX,gCAAgC;;CAEhC;EACC,eAAe;EACf,iBAAiB;EACjB,kBAAkB;CACnB;AACD;;AAEA;CACC,qBAAqB;CACrB,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,SAAS;CACT,kBAAkB;CAClB,gBAAgB;CAChB,WAAW;CACX,gCAAgC;CAChC,qBAAqB;CACrB;;EAEC,gCAAgC;EAChC,cAAc;CACf;CACA;EACC,eAAe;EACf,iBAAiB;EACjB,eAAe;CAChB;AACD","sourcesContent":[".welcomeSection {\n\t/* text-align: center; */\n\theight: 500px;\n\tbackground-image: url('../../images/KarieraWelcome.jpg');\n\tbackground-size: cover;\n\tbackground-position: center;\n\tbackground-repeat: no-repeat;\n\t@media screen and (max-width: 768px) {\n\t\theight: 160px;\n\t\tbackground-image: url('../../images/bgKarieraMob.jpg');\n\t}\n}\n\n.text {\n\tfont-size: 36px;\n\tfont-weight: 600;\n\tline-height: 1.2;\n\tmargin: 0;\n\tpadding-top: 200px;\n\tpadding-left: 140px;\n\tcolor: #fff;\n\ttext-shadow: 0 4px 4px #00000099;\n\n\t@media screen and (max-width: 768px) {\n\t\tfont-size: 16px;\n\t\tpadding-top: 70px;\n\t\tpadding-left: 20px;\n\t}\n}\n\n.textPhone {\n\tdisplay: inline-block;\n\tfont-size: 32px;\n\tfont-weight: 600;\n\tline-height: 1.2;\n\tmargin: 0;\n\tmargin-left: 140px;\n\tmargin-top: 15px;\n\tcolor: #fff;\n\ttext-shadow: 0 4px 4px #00000099;\n\ttext-decoration: none;\n\t&:hover,\n\t&:focus {\n\t\t/* text-decoration: underline; */\n\t\tcolor: #ffa502;\n\t}\n\t@media screen and (max-width: 768px) {\n\t\tfont-size: 16px;\n\t\tmargin-left: 20px;\n\t\tmargin-top: 5px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeSection": `KarieraWelcome_welcomeSection__U6kGD`,
	"text": `KarieraWelcome_text__MsTT7`,
	"textPhone": `KarieraWelcome_textPhone__EzMDB`
};
export default ___CSS_LOADER_EXPORT___;
