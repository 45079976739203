// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/TechnikaWelcomeBg.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/bgTechnikaMob.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DomyWelcome_welcomeSection__H5fjW {
	height: 800px;
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@media screen and (max-width: 768px) {
		height: 180px;
		background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/DomyWelcome/DomyWelcome.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,yDAA2D;CAC3D,sBAAsB;CACtB,2BAA2B;CAC3B,4BAA4B;;CAE5B;EACC,aAAa;EACb,yDAAuD;CACxD;AACD","sourcesContent":[".welcomeSection {\n\theight: 800px;\n\tbackground-image: url('../../images/TechnikaWelcomeBg.jpg');\n\tbackground-size: cover;\n\tbackground-position: center;\n\tbackground-repeat: no-repeat;\n\n\t@media screen and (max-width: 768px) {\n\t\theight: 180px;\n\t\tbackground-image: url('../../images/bgTechnikaMob.jpg');\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeSection": `DomyWelcome_welcomeSection__H5fjW`
};
export default ___CSS_LOADER_EXPORT___;
