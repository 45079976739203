// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

ul {
	list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
figure,
blockquote,
dl,
dd {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

button,
input {
	border: none;
	background: none;
	outline: none;
}

body {
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	line-height: 1.2;
	color: #000;
}

code {
	font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.app {
	/* max-width: 1440px; */
	max-width: 2560px;
	margin-left: auto;
	margin-right: auto;
}

.active {
	color: white;
	background: #ffa502;
	padding: 10px 20px;
	align-items: start;

	@media screen and (max-width: 768px) {
		color: white !important;
		width: 320px;
		padding: 5px 5px 5px 25px;
		text-align: center;
		border-radius: 0 10px 10px 0;
		font-weight: 500;
	}
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;;;;;;;;;;;;;;;CAeC,SAAS;CACT,UAAU;AACX;;AAEA;CACC,qBAAqB;CACrB,cAAc;AACf;;AAEA;;CAEC,YAAY;CACZ,gBAAgB;CAChB,aAAa;AACd;;AAEA;CACC;;YAEW;CACX,mCAAmC;CACnC,kCAAkC;CAClC,eAAe;CACf,gBAAgB;CAChB,WAAW;AACZ;;AAEA;CACC;WACU;AACX;;AAEA;CACC,uBAAuB;CACvB,iBAAiB;CACjB,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;;CAElB;EACC,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,4BAA4B;EAC5B,gBAAgB;CACjB;AACD","sourcesContent":["* {\n\tmargin: 0;\n\tpadding: 0;\n\tbox-sizing: border-box;\n}\n\nul {\n\tlist-style: none;\n}\n\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\na,\nul,\nli,\nfigure,\nblockquote,\ndl,\ndd {\n\tmargin: 0;\n\tpadding: 0;\n}\n\na {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\nbutton,\ninput {\n\tborder: none;\n\tbackground: none;\n\toutline: none;\n}\n\nbody {\n\tfont-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',\n\t\t'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n\t\tsans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n\tfont-size: 18px;\n\tline-height: 1.2;\n\tcolor: #000;\n}\n\ncode {\n\tfont-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n\t\tmonospace;\n}\n\n.app {\n\t/* max-width: 1440px; */\n\tmax-width: 2560px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n}\n\n.active {\n\tcolor: white;\n\tbackground: #ffa502;\n\tpadding: 10px 20px;\n\talign-items: start;\n\n\t@media screen and (max-width: 768px) {\n\t\tcolor: white !important;\n\t\twidth: 320px;\n\t\tpadding: 5px 5px 5px 25px;\n\t\ttext-align: center;\n\t\tborder-radius: 0 10px 10px 0;\n\t\tfont-weight: 500;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
