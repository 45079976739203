// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/TechnikaWelcomeBg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NieruchomośćWelcome_welcomeSection__N8nJI {
	/* text-align: center; */
	height: 600px;
	background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.NieruchomośćWelcome_title__VNffE {
	color: #fff;
	font-size: 50px;
	font-weight: 700;
	line-height: 1.2;
	padding: 30px 0 0 140px;
	text-shadow: 0 4px 4px #00000099;
}
`, "",{"version":3,"sources":["webpack://./src/components/NieruchomośćWelcome/Nieruchomo%C5%9B%C4%87Welcome.module.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB,aAAa;CACb,yDAA2D;CAC3D,sBAAsB;CACtB,2BAA2B;CAC3B,4BAA4B;AAC7B;;AAEA;CACC,WAAW;CACX,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,uBAAuB;CACvB,gCAAgC;AACjC","sourcesContent":[".welcomeSection {\n\t/* text-align: center; */\n\theight: 600px;\n\tbackground-image: url('../../images/TechnikaWelcomeBg.jpg');\n\tbackground-size: cover;\n\tbackground-position: center;\n\tbackground-repeat: no-repeat;\n}\n\n.title {\n\tcolor: #fff;\n\tfont-size: 50px;\n\tfont-weight: 700;\n\tline-height: 1.2;\n\tpadding: 30px 0 0 140px;\n\ttext-shadow: 0 4px 4px #00000099;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeSection": `NieruchomośćWelcome_welcomeSection__N8nJI`,
	"title": `NieruchomośćWelcome_title__VNffE`
};
export default ___CSS_LOADER_EXPORT___;
